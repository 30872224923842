import { Row } from "antd";
import { impulso } from "../src/assets/images";

const Closed = () => {
  return (
    // <div className="closed">
    // <h1>Sorry, we are closed</h1>
    // </div>
    <>
      <Row justify={"center"}>
        <img
          src={impulso}
          style={{
            maxWidth: "100%",
            maxHeight: "278px",
          }}
        />
      </Row>
      <Row justify={"center"}>
        <h3>El periodo de inscripción ha concluido.</h3>
      </Row>
    </>
  );
};

export default Closed;
