import styled from "styled-components";

const Status = styled.div`
  justify-content: flex-end;
  position: fixed;
  width: 80%;
  display: flex;
  z-index: 11;
`;

const StatusCircle = styled.span`
  color: ${(props) => props.color};
  text-shadow: 0px 0px ${(props) => (props.color === "white" ? "2px" : "0px")}
    #000;
`;

export { Status, StatusCircle };
