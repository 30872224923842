import { FC, useState, useEffect } from "react";
import { IGizmo, RootGizmo } from "flowy-3-core";
import { MenuProps, Space, Dropdown, Button } from "antd";
import { DownOutlined } from "@ant-design/icons";
import * as S from "./Status.styles";

type StatusProps = {
  rootGizmo: RootGizmo;
};

interface IItem {
  label: any;
  key: string;
}

const Status: FC<StatusProps> = ({ rootGizmo }) => {
  const [display, setDisplay] = useState(false);
  const [currentStatus, setCurrentStatus] = useState<IItem | undefined>();
  const [statusItems, setStatusItems] = useState<IItem[]>([]);

  useEffect(() => {
    const config: IGizmo = rootGizmo.getConfig();
    const data = rootGizmo.getData();
    const params = rootGizmo.getParams();

    // Current Status
    let cs = config.status?.find((s) => s.f_id === data?.status);

    if (cs === undefined) {
      cs = config.status?.find((s) => s.f_id === "s0");
    }

    if (cs) {
      setCurrentStatus(statusToItem(cs));
      const statusItems = cs.actions
        .filter((a) => Object.keys(a).length > 0)
        .map((a) => statusToItem(a));
      setStatusItems(statusItems);
    }

    const isCreating = rootGizmo.getInfoCB({
      type: "rootGizmoDataCreate",
    });

    if (
      config.ops?.form?.status?.onCreate?.display?.isDisplayed === true &&
      isCreating &&
      params &&
      !params.fid
    ) {
      setDisplay(true);
    } else if (
      config.ops?.form?.status?.onUpdate?.display?.isDisplayed === true &&
      !isCreating &&
      params &&
      params.fid &&
      !params.m
    ) {
      setDisplay(true);
    }
  }, [rootGizmo]);

  const handleMenuClick: MenuProps["onClick"] = async (e) => {
    const config = rootGizmo.getConfig();
    const nextStatus = config.status?.find((s) => s.f_id === e.key);

    if (nextStatus) {
      await rootGizmo.setStatus(nextStatus.f_id, false);
      setCurrentStatus(statusToItem(nextStatus));
    }
  };

  return (
    <>
      {display && (
        <S.Status id="status">
          <Space>
            <Dropdown
              menu={{ onClick: handleMenuClick, items: statusItems }}
              trigger={["click"]}
            >
              <Button>
                <Space>
                  {currentStatus?.label}
                  <DownOutlined />
                </Space>
              </Button>
            </Dropdown>
          </Space>
        </S.Status>
      )}
    </>
  );
};

/**
 * Transform a status object to an item so it's compatible with the Dropdown component
 *
 * @param {any} status status object
 * @returns
 */
function statusToItem(status: any): IItem {
  return {
    label: (
      <>
        <S.StatusCircle color={status.color}>◉ </S.StatusCircle>
        {status.title}
      </>
    ),
    key: status.f_id,
  };
}

export default Status;
