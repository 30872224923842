import { Checkbox, Select } from "antd";
import styled, { css } from "styled-components";

const SelectField = styled(Select)`
  width: 100%;
`;

const SelectTristate = styled(Checkbox)`
  ${(props) =>
    props.indeterminate &&
    css`
      > .ant-checkbox-indeterminate .ant-checkbox-inner:after {
        background-color: #fff;
        border-color: gray;
      }

      > .ant-checkbox-indeterminate .ant-checkbox-inner {
        background-color: #fff !important;
        border-color: gray;
      }
    `}

  ${(props) =>
    props.checked &&
    css`
      > .ant-checkbox > .ant-checkbox-inner {
        background-color: green;
        border-color: gray;
      }

      &:hover {
        > .ant-checkbox > .ant-checkbox-inner {
          border-color: gray !important;
          background-color: green !important;
        }
      }

      > .ant-checkbox-inner {
        background-color: green !important;
        border-color: green !important;
      }

      :where(.css-dev-only-do-not-override-10q9tcx).ant-checkbox-checked:after {
        border-color: gray !important;
      }

      :where(.css-dev-only-do-not-override-10q9tcx).ant-checkbox-wrapper:not(
          .ant-checkbox-wrapper-disabled
        ):hover
        .ant-checkbox-checked:not(.ant-checkbox-disabled):after {
        border-color: gray !important;
      }
    `}

${(props) =>
    !props.checked &&
    css`
      > .ant-checkbox > .ant-checkbox-inner::before {
        content: "X";
        color: white;
        font-weight: bold;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      > .ant-checkbox > .ant-checkbox-inner {
        background-color: red;
        border-color: gray;
      }
    `}
`;

export { SelectField, SelectTristate };
