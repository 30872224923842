import React, { FC, useState, useEffect } from "react";
import { Gizmo, ILocationData, useGizmo } from "flowy-3-core";
import {
  Button,
  Divider,
  Input,
  Modal,
  Select,
  Tooltip,
  Typography,
} from "antd";
import { DeleteTwoTone, EditTwoTone } from "@ant-design/icons";
import { GizmoWrapper } from "../../utils";
import GoogleLocation from "./GoogleLocation";
import OPSLocation from "./OPSLocation";
import OSMLocations from "./OSMLocations";
import clone from "clone";

type LocationFieldProps = {
  gizmo: Gizmo;
};

export type LocationMapProps = {
  currentLocation: google.maps.LatLngLiteral | null;
  setCurrentLocation: (loc: google.maps.LatLngLiteral | null) => void;
  selectedLocation: google.maps.LatLngLiteral | null;
  setSelectedLocation: (loc: google.maps.LatLngLiteral | null) => void;
};

const { Text } = Typography;

const mapOptions = [
  { value: "google", label: "Google Maps" },
  {
    value: "ops",
    label: "Open Street Maps",
  },
];

const LocationField: FC<LocationFieldProps> = ({ gizmo }) => {
  const { features, binder, errors, config } = useGizmo({ gizmo });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [mapOption, setMapOption] = useState("ops");
  const [locations, setLocations] = useState<ILocationData[]>([]);

  // TODO: remove this
  const [currentLocation, setCurrentLocation] =
    useState<google.maps.LatLngLiteral | null>(null);
  const [selectedLocation, setSelectedLocation] =
    useState<google.maps.LatLngLiteral | null>(null);

  const osmRef = React.createRef<any>();

  useEffect(() => {
    if (binder) {
      const locations: ILocationData[] = binder.getLocations();

      if (locations.length === 1) {
        setLocations(locations);
        // setSelectedLocation({
        //   lat: locations[0].latitude,
        //   lng: locations[0].longitude,
        // });
      }

      binder.locations.subscribe((locs: ILocationData[]) => {
        setLocations(locs);

        // setSelectedLocation({
        //   lat: locs[0].latitude,
        //   lng: locs[0].longitude,
        // });
      });
    }
  }, []);

  const onChangeDescription = (e: any) => {
    console.log("EEEEE", e.target.value);
  };

  const handleSetSelectedLocation = async (
    loc: google.maps.LatLngLiteral | null
  ) => {
    await binder?.setLocation({
      latitude: loc?.lat,
      longitude: loc?.lng,
    });
  };

  const renderMap = () => {
    if (mapOption === "google") {
      return (
        <GoogleLocation
          currentLocation={currentLocation}
          selectedLocation={selectedLocation}
          setCurrentLocation={setCurrentLocation}
          setSelectedLocation={handleSetSelectedLocation}
        />
      );
    } else if (mapOption === "ops") {
      return (
        // <OPSLocation
        //   currentLocation={currentLocation}
        //   selectedLocation={selectedLocation}
        //   setCurrentLocation={setCurrentLocation}
        //   setSelectedLocation={handleSetSelectedLocation}
        // />
        <OSMLocations
          ref={osmRef}
          gizmo={gizmo}
          features={features}
          locations={locations}
        />
      );
    }
  };

  const handleModalOk = async () => {
    const locations = osmRef.current?.getLocations();

    if (locations && locations.length === 1 && binder) {
      await binder.setLocation(clone(locations[0]));
      setIsModalOpen(false);
    }
  };

  const handleModalCancel = () => {
    setIsModalOpen(false);
    osmRef.current?.onCancel();
  };

  const handleDeleteClick = async () => {
    await binder?.setLocation(undefined);
  }

  return (
    <GizmoWrapper features={features} errors={errors}>
      {locations.length === 0 && (
        <Button onClick={() => setIsModalOpen(true)}>Agregar ubicación</Button>
      )}
      {locations.length === 1 && (
        <div>
          <Text>
            {locations[0]?.latitude}, {locations[0].longitude}
            {features.editable && (
              <>
                <Divider type="vertical" />
                <Tooltip title="Editar">
                  <EditTwoTone onClick={() => setIsModalOpen(true)} />
                </Tooltip>
                <Divider type="vertical" />
                <Tooltip title="Eliminar">
                  <DeleteTwoTone
                    twoToneColor="#F00"
                    onClick={handleDeleteClick}
                    style={{
                      cursor: "pointer",
                    }}
                  />
                </Tooltip>
              </>
            )}
          </Text>
        </div>
      )}
      {/* <Input placeholder="Descripción" onChange={onChangeDescription} /> */}
      <Modal
        title="Agregar ubicación"
        open={isModalOpen}
        width={"90%"}
        onCancel={handleModalCancel}
        onOk={handleModalOk}
      >
        {/* <Select
          defaultValue="ops"
          options={mapOptions}
          onChange={onChangeMapOption}
          style={{ width: 200 }}
        /> */}
        {renderMap()}
      </Modal>
    </GizmoWrapper>
  );
};
export default LocationField;
