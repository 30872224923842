import { Input } from "antd";
import styled from "styled-components";
import { GizmoContainerResize } from "../../utils";

const Index = styled(GizmoContainerResize)`
  // border: 2px dashed blue;
`;

const { Search: SearchAntd } = Input;
const Search = styled(SearchAntd)`
  width: 250px;
`;

const Circle = styled.span`
  color: ${(props) => props.color};
  text-shadow: 0px 0px ${(props) => (props.color === "white" ? "2px" : "0px")}
    #000;
`;

const HeaderTitle = styled.span`
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 15vw;
  // text-decoration: underline;
`;

export { Index, Search, Circle, HeaderTitle };
