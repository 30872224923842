import { FC, useEffect, useState } from "react";
import { Gizmo, useGizmo } from "flowy-3-core";
import * as S from "./Folio.styles";
import { GizmoWrapper } from "../../utils";
import { DownloadOutlined, ShareAltOutlined } from "@ant-design/icons";
import { Button, Divider, Modal, QRCode, Space } from "antd";
import {
  WhatsappIcon,
  WhatsappShareButton,
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
  EmailShareButton,
  EmailIcon,
  TelegramShareButton,
  TelegramIcon,
} from "react-share";
import { downloadQRCode } from "../../utils/functions";

type FolioProps = {
  gizmo: Gizmo;
};
const baseUrl = new URL(window.location.href).origin;
const Folio: FC<FolioProps> = ({ gizmo }) => {
  const [folio, setFolio] = useState<string | undefined>();
  const [url, setUrl] = useState<string | undefined>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { config, binder, features, errors } = useGizmo({ gizmo });

  useEffect(() => {
    if (binder) {
      const value = binder.getValue();
      if (value) {
        setFolio(value);
        setUrl(`Tu folio es: ${baseUrl}/#/qr_generator?folio=${value}`);
      }
    }
  }, [binder]);

  const openShareModal = () => {
    setIsModalOpen(true);
  };

  const closeShareModal = () => {
    setIsModalOpen(false);
  };

  return (
    <GizmoWrapper features={features} errors={errors}>
      <S.Folio id={`folio-${config.fid}`} columns={gizmo.getColumns()}>
        {folio && <h3>{folio}</h3>}
        {folio &&
          !folio.includes("cuando se suba la ") &&
          config.ops?.folio?.showFolioAsQr === true && (
            <Space direction="vertical" align="center">
              <QRCode size={150} value={folio} className="folio-image" />
              <div>
                <Button
                  type="primary"
                  icon={<DownloadOutlined />}
                  onClick={() => downloadQRCode("folio-image", folio)}
                />
                <Divider type="vertical" />
                <Button
                  type="primary"
                  icon={<ShareAltOutlined />}
                  onClick={openShareModal}
                />
              </div>
            </Space>
          )}
      </S.Folio>
      {url && (
        <Modal
          title="Compartir en:"
          open={isModalOpen}
          onCancel={closeShareModal}
          footer={null}
        >
          <WhatsappShareButton url={url}>
            <WhatsappIcon size={40} round={true} />
          </WhatsappShareButton>
          <FacebookMessengerShareButton url={url} appId="">
            <FacebookMessengerIcon size={40} round={true} />
          </FacebookMessengerShareButton>
          <EmailShareButton url={url}>
            <EmailIcon size={40} round={true} />
          </EmailShareButton>
          <TelegramShareButton url={url}>
            <TelegramIcon size={40} round={true} />
          </TelegramShareButton>
        </Modal>
      )}
    </GizmoWrapper>
  );
};

export default Folio;
